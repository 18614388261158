import React from 'react';
import './Header.css';

const Header = ({ img, alt }) => {
    return (
        <div className="Header">
            <img className="page-img" width="100%" src={img} alt={alt}></img>
        </div>
    );
}

export default Header;