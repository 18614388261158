import React from "react";

const PlayIcon = () => {
  return (
    <svg width="45px" height="46px" viewBox="0 0 45 46" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Home" transform="translate(-700.000000, -1258.000000)" fill="#FFFFFF">
          <g id="Group-13" transform="translate(245.000000, 895.000000)">
            <g id="Actions-/-Controls-/-play--filled-/-32" transform="translate(431.000000, 341.000000)">
              <path d="M27.2142325,67.2 C25.4390609,67.2 24,65.7609273 24,63.9857412 L24,25.4146352 C24,24.3005234 24.5766773,23.2657992 25.5243839,22.6800668 C26.4720905,22.0943344 27.6555231,22.0411384 28.6519586,22.5394806 L67.2227479,41.8250336 C68.3119358,42.3694104 69,43.4825282 69,44.7001882 C69,45.9178481 68.3119358,47.0309659 67.2227479,47.5753427 L28.6519586,66.8608957 C28.2055011,67.0839039 27.7132878,67.2 27.2142325,67.2 L27.2142325,67.2 Z" id="_inner-path_" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PlayIcon;
