import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';

// Changing colour of logo:
    // find rgb value of logo and then search for r/255, g/255, or b/255

class AnimatedLogo extends Component {
    constructor(props) {
        super(props);

        this.myRef = createRef();
        this.animation = null;
        this.direction = 1;

        this.startAnimation = this.startAnimation.bind(this);
        this.reverseAnimation = this.reverseAnimation.bind(this);
        this.completeAnimation = this.completeAnimation.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        const {asLoader, isLoading, delay} = this.props;
        return asLoader !== nextProps.asLoader || isLoading !== nextProps.isLoading || delay !== nextProps.delay;
    }

    async componentDidMount() {
        const { delay } = this.props;
        setTimeout(this.startAnimation, delay);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading && prevProps.isLoading !== this.props.isLoading) this.completeAnimation();
    }

    startAnimation() {
        const { asLoader, isLoading } = this.props;
        const animationData = require('../../assets/images/logo/animatedLogoData.json');

        if (this.animation) this.animation.destroy();

        this.animation = lottie.loadAnimation({
            container: this.myRef,
            renderer: 'svg',
            loop: false,
            autoplay: true,
            animationData: animationData
        });

        if (asLoader && isLoading) {
            this.animation.addEventListener('complete', this.reverseAnimation);
        }
    }

    reverseAnimation() {
        // eslint-disable-next-line no-unused-expressions
        this.direction *= -1;
        this.animation.setDirection(this.direction);
        this.animation.play();
    }

    completeAnimation() {
        this.animation.removeEventListener('complete', this.reverseAnimation);
        this.animation.addEventListener('complete', () => {
            this.animation.setDirection(1);
            this.animation.play();
        });
    }

    render () {
        return (
            <div ref={ref => this.myRef = ref} className="w-100 h-100" />
        );
    }
}

AnimatedLogo.propTypes = {
    asLoader: PropTypes.bool,
    isLoading: (props, propName) => {
        if ((props['asLoader'] === true && (props[propName] === undefined || typeof(props[propName]) !== 'boolean'))) {
            return new Error(
                'AnimatedLogo: "isLoading" is a required prop when "asLoader" is true'
            );
        }
    },
    delay: PropTypes.number,
};

AnimatedLogo.defaultProps = {
    asLoader: false,
    isLoading: false,
    delay: 0,
};

export default AnimatedLogo;
