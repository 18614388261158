import React from 'react';
import App from './components/app/App';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import './assets/css/main.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-modal-video/scss/modal-video.scss';

function RootApp() {

    return (
        <BrowserRouter>
            <ScrollToTop>
                <App/>
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default RootApp;
