import React, {useEffect} from 'react';
import './ListingLayout.css';

const ListingLayout = ({opening}) => {

  useEffect(() => {
    document.title = opening.title + ' — Grain Discovery';
  });

  return (
    <div className="Listing">

      <div className="section-block">
        <h1>{opening.title}</h1>
        <p>{opening.location} | {opening.duration}</p>
      </div>

      <div className="section-block left">

        <p>{opening.description}</p>
        {opening.content}
      </div>

    </div>

  );

}

export default ListingLayout;