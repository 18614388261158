import React from 'react';
import { Form, Input, Button, Spin, Row, Col } from 'antd';
import './Subscribe.scss';

const Subscribe = ({form, onValidated, status, message, width}) => {

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                onValidated({
                    EMAIL: values.email,
                    FNAME: values.firstName,
                    LNAME: values.lastName
                });
            }
        })
    };

    const { getFieldDecorator } = form;

    return (
        <div className="form">
            <Form layout="horizontal" onSubmit={handleSubmit}>

                <Form.Item label="Email address">
                    {getFieldDecorator('email', {
                        rules: [
                            {
                                required: true,
                                message: 'Please input your email address',
                            },
                        ],
                    })(
                        <div className="input-group">
                            <Input placeholder="Email address" />
                            <Button type="primary" htmlType="submit" className="btn-primary">
                                Subscribe
                            </Button>
                        </div>
                    )}
                </Form.Item>

            </Form>



            {status === "sending" && <Spin className="spinner" />}


            {status === "error" && (
                <div className="form-result error"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <div
                    className="form-result success"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
        </div>

    );
}

export const WrappedSubscribeForm = Form.create({ name: 'mailchimp-subscibe-form' })(Subscribe)
