import React, { useEffect, useState } from 'react';

// components
import AccessOurPlatform from "../../components/products/accessOurPlatform/AccessOurPlatform";
import Products from "../../components/products/products/Products";
import DownloadModal from "../../components/downloadModal/DownloadModal";

//assets
import './ProductsPage.scss';
import img from '../../assets/images/headers/book.jpg';
import { Button } from "antd";

const ProductsPage = () => {

  useEffect(() => {
    document.title = "Book a Demo — Grain Discovery"
  });

  const [isDownloadOpen, setIsDownloadOpen] = useState(false);

  const openDownloadModal = () => {
    setIsDownloadOpen(true);
  }

  const closeDownloadModal = () => {
    setIsDownloadOpen(false);
  }

  return (
    <div className="book">
      <div className="banner" style={{ backgroundImage: `url(${img})` }}>
        <div className="banner-container">
          <div className="title-container">
            <h1 className="title">A Better Experience For <br /><span>Your Agri-Business</span></h1>
          </div>
          <Button className="btn-primary" onClick={openDownloadModal}>Book A Demo</Button>
        </div>
      </div>

      <div className="container">
        <AccessOurPlatform />
      </div>

      <div className="container-fluid section">
        <Products />
      </div>

      <DownloadModal isOpen={isDownloadOpen} onClose={closeDownloadModal} showDemo={true} />
    </div>
  );

}


export default ProductsPage;
