import React from 'react';
import { Button } from 'antd';

import img from '../../../assets/images/home/market.svg'
import './MarketYourGrain.scss'

const MarketYourGrain = ({ openDownloadModal }) => {

    return (
        <div className="home-section-3">
            <div className="title">
                <h2>Transform your Agri-Food Supply Chain</h2>
                <p>Designed for scalability, caters to six verticals: elevators, seed, <br />
                    feedlots, brokers, advisors, and food processors.
                </p>
            </div>

            <div className="content">
                <div className="img-wrapper">
                    <img src={img} alt="Market Your Grain Your Way" />
                </div>

                <div className="footer">
                    <Button
                        type="primary"
                        onClick={() => openDownloadModal(true)}
                    >
                        Bring Your Business Online
                    </Button>
                </div>
            </div>
        </div>
    );

}

export default MarketYourGrain;
