import React, { useState, useEffect } from "react";
import QRCode from "qrcode";
import axios from "axios";

// components
import elevators from "./elevators";
import { Button, Select, Row, Col, Input } from "antd";

// assets
import "./DownloadModal.scss";
import appStoreIcon from "../../assets/images/downloadModal/app-store.svg";
import googlePlayIcon from "../../assets/images/downloadModal/google-play.svg";
import desktopIcon from "../../assets/images/downloadModal/desktop.svg";

const DownloadModal = (props) => {
	// get API URL
	const firebaseURL =
		process.env.REACT_APP_FIREBASE_URL ||
		"https://us-central1-graindiscovery-web.cloudfunctions.net/";

	// constants
	const appTypes = [
		{
			name: "App Store",
			icon: appStoreIcon,
		},
		{
			name: "Google Play",
			icon: googlePlayIcon,
		},
		{
			name: "Desktop",
			icon: desktopIcon,
		},
	];
	const emptyDemoForm = {
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		companyName: "",
		type: undefined,
		comment: "",
	};
	const GENERIC_DESKTOP = "http://marketplace.graindiscovery.com/",
		GENERIC_APP_STORE =
			"https://apps.apple.com/ca/app/grain-discovery-explore/id1563057814?uo=4",
		GENERIC_GOOGLE_PLAY =
			"https://play.google.com/store/apps/details?id=com.graindiscovery.marketplace_flutter";

	// setup state
	const [stage, setStage] = useState(1);
	const [elevator, setElevator] = useState(undefined);
	const [appType, setAppType] = useState(undefined);
	const [qrCodeURI, setQRCodeURI] = useState(undefined);
	const [appStoreLink, setAppStoreLink] = useState("#");
	const [googlePlayLink, setGooglePlayLink] = useState("#");
	const [newElevator, setNewElevator] = useState(undefined);
	const [demoForm, setDemoForm] = useState(emptyDemoForm);
	const [userSignupInfo, setUserSignupInfo] = useState({
		email: "",
		phone: "",
	});
	const [signupFormSubmitted, setSignupFormSubmitted] = useState(false);

	const { Option } = Select;
	const { TextArea } = Input;

	// handle opening/closing the modal
	useEffect(() => {
		if (props.isOpen && props.showDemo) {
			// take user directly to the demo form
			setStage(4);
			setDemoForm({
				...demoForm,
				type: "Grain Buyer",
			});
		} else if (props.isOpen) {
			setStage(1);
		}
	}, [props.isOpen, props.showDemo]);

	// control the elevator form select
	const changeElevator = (value) => {
		setElevator(value);
	};

	const getGenericQRCode = (appType) => {
		let url;
		switch (appType) {
			case "App Store":
				url = GENERIC_APP_STORE;
				break;
			case "Google Play":
				url = GENERIC_GOOGLE_PLAY;
				break;
			case "Desktop":
			default:
				url = GENERIC_DESKTOP;
				break;
		}
		setAppStoreLink(GENERIC_APP_STORE);
		setGooglePlayLink(GENERIC_GOOGLE_PLAY);
		QRCode.toDataURL(url, function (err, url) {
			setQRCodeURI(url);
		});
	};

	const takeUserToDesktop = (url) => {
		window.open(url, "_blank").blur();
	};

	// handle changing of the signup form
	const changeSignupForm = (e) => {
		const { name, value } = e.target;
		setUserSignupInfo({
			...userSignupInfo,
			[name]: value,
		});
	};

	const submitNewSignupRequest = (e) => {
		e.preventDefault();

		const params = new URLSearchParams();
		params.append("elevator", newElevator || elevator);
		params.append("email", userSignupInfo.email);
		params.append("phone", userSignupInfo.phone);
		axios
			.get(firebaseURL + "elevatorContactRequest", { params })
			.then(function (_) {
				setSignupFormSubmitted(true);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	// move between stages
	const moveStage = (stageNum, e) => {
		if (e) e.preventDefault();

		if (stageNum == 2 && newElevator) {
			// submit new elevator request
			const params = new URLSearchParams();
			params.append("elevator", newElevator);
			axios
				.get(firebaseURL + "elevatorNotFound", { params })
				.then(function (response) {
					// take user to generic download
					setNewElevator(undefined);
					getGenericQRCode(appType);
					setStage(stageNum);
				})
				.catch(function (error) {
					console.log(error);
				});
		} else if (stageNum == 2) {
			if (elevator == "new") {
				// take user to bring new elevator online stage
				setStage(3);
			} else {
				// get App store url
				let url, emails;
				elevators.forEach((elevatorEl) => {
					if (elevatorEl.name === elevator) {
						emails = elevatorEl.emails;
						switch (appType) {
							case "App Store":
								url = elevatorEl.app_store;
								break;
							case "Google Play":
								url = elevatorEl.play_store;
								break;
							case "Desktop":
							default:
								url = elevatorEl.desktop;
								break;
						}
						setAppStoreLink(elevatorEl.app_store || GENERIC_APP_STORE);
						setGooglePlayLink(elevatorEl.play_store || GENERIC_GOOGLE_PLAY);
					}
				});

				// build QR code
				if (url && appType === "Desktop") {
					takeUserToDesktop(url);
				} else if (url) {
					QRCode.toDataURL(url, function (err, url) {
						setQRCodeURI(url);
					});
					setStage(stageNum);
				} else {
					// no QR code found, take to generic app qr code but also send request
					const params = new URLSearchParams();
					params.append("elevator", elevator);
					params.append("platform", appType);
					params.append("emails", emails);
					axios
						.get(firebaseURL + "qrCodeNotFound", { params })
						.then(function (response) {
							if (appType === "Desktop") {
								takeUserToDesktop(GENERIC_DESKTOP);
							}
							getGenericQRCode(appType);
							setStage(stageNum);
						})
						.catch(function (error) {
							console.log(error);
						});
				}
			}
		} else {
			setStage(stageNum);
		}
	};

	// handle changing of the demo form
	const changeDemoForm = (e) => {
		const { name, value } = e.target;
		setDemoForm({
			...demoForm,
			[name]: value,
		});
	};

	// handle submit of the demo form
	const bookDemo = (e) => {
		e.preventDefault();
		const params = new URLSearchParams();
		params.append("firstName", demoForm.firstName);
		params.append("lastName", demoForm.lastName);
		params.append("email", demoForm.email);
		params.append("phone", demoForm.phone);
		params.append("companyName", demoForm.companyName);
		params.append("type", demoForm.type);
		params.append("comment", demoForm.comment);

		axios
			.post(firebaseURL + "demoRequest", params, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then(function (response) {
				// show user thank you stage
				setDemoForm(emptyDemoForm);
				setStage(5);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	// not open, return empty div
	if (!props.isOpen) return <div></div>;

	let showBackButton;
	let modalInner;

	if (stage == 1) {
		let appTypesHTML = [];
		appTypes.forEach((appTypeEl, i) => {
			appTypesHTML.push(
				<Col className="type" md={8} key={i}>
					<input
						type="radio"
						name="type"
						value={appTypeEl.name}
						id={"app_" + i}
						checked={appType === appTypeEl.name}
						onChange={(e) => setAppType(e.target.value)}
					/>
					<label htmlFor={"app_" + i}>
						<img src={appTypeEl.icon} alt={appTypeEl.name} />
						<span>{appTypeEl.name}</span>
						<div></div>
					</label>
				</Col>
			);
		});

		let elevatorsHTML = [];
		elevators.sort(function (a, b) {
			let textA = a.name.toUpperCase();
			let textB = b.name.toUpperCase();
			return textA < textB ? -1 : textA > textB ? 1 : 0;
		});
		elevators.forEach((elevator, i) => {
			elevatorsHTML.push(
				<Option data-index={i} value={elevator.name} key={i}>
					{elevator.name}
				</Option>
			);
		});

		let buttonTitle = "Download";
		if (elevator === "new") {
			buttonTitle = "Suggest Elevator";
		} else if (appType === "Desktop") {
			buttonTitle = "Login";
		}

		showBackButton = false;
		modalInner = (
			<div>
				<h2>Download Your App</h2>
				<form onSubmit={(e) => moveStage(2, e)}>
					<Select
						defaultValue=""
						value={elevator}
						showSearch
						placeholder="Select your elevator"
						onChange={changeElevator}
					>
						<Option value="new">Suggest your elevator to us</Option>
						{elevatorsHTML}
					</Select>
					<Row className="app-types" gutter={16}>
						{appTypesHTML}
					</Row>
					<div className="text-center">
						<Button type="primary" htmlType="submit">
							{buttonTitle}
						</Button>
					</div>
				</form>
				<p>
					Interested in learning more?{" "}
					<Button type="link" onClick={() => moveStage(4)}>
						Book A Demo
					</Button>
				</p>
			</div>
		);
	} else if (stage == 2) {
		showBackButton = true;
		modalInner = (
			<div>
				<h2>Download Your App</h2>
				<br />
				{signupFormSubmitted ? (
					<div>
						<h2>Thanks!</h2>
						<p className="sub-text">
							Your request has been submitted and we will contact you.
						</p>
					</div>
				) : (
					<div>
						<h4>Enter your info and we'll set you up with an account</h4>
						<form onSubmit={submitNewSignupRequest} className="user-info-form">
							<Col md={12} className="form-col">
								<Input
									type="text"
									value={userSignupInfo.email}
									placeholder="Email Address"
									name="email"
									onChange={changeSignupForm}
								/>
							</Col>
							<Col md={12} className="form-col">
								<Input
									type="text"
									value={userSignupInfo.phone}
									placeholder="Phone Number"
									name="phone"
									onChange={changeSignupForm}
								/>
							</Col>
							<div className="text-center">
								<Button type="primary" htmlType="submit">
									Submit
								</Button>
							</div>
						</form>
					</div>
				)}
				<br />
				<p className="sub-text">
					Scan the QR code to experience all the features today!
				</p>
				<img src={qrCodeURI} className="qr-code" alt="QR code" />
				<p>
					View in <a href={appStoreLink}>App Store</a> or{" "}
					<a href={googlePlayLink}>Google Play</a>
				</p>
			</div>
		);
	} else if (stage == 3) {
		showBackButton = true;
		modalInner = (
			<div>
				<h2>Bring Your Elevator Online</h2>
				<form onSubmit={(e) => moveStage(2, e)}>
					<Input
						placeholder="What is the name of the elevator?"
						value={newElevator}
						onChange={(e) => setNewElevator(e.target.value)}
					/>
					<div className="text-center">
						<Button type="primary" htmlType="submit">
							Continue
						</Button>
					</div>
				</form>
			</div>
		);
	} else if (stage == 4) {
		showBackButton = true;
		modalInner = (
			<div>
				<h2>Book A Demo</h2>
				<form onSubmit={bookDemo}>
					<Row gutter={16}>
						<Col md={12} className="form-col">
							<Input
								type="text"
								value={demoForm.firstName}
								placeholder="First name"
								name="firstName"
								onChange={changeDemoForm}
							/>
						</Col>
						<Col md={12} className="form-col">
							<Input
								type="text"
								value={demoForm.lastName}
								placeholder="Last name"
								name="lastName"
								onChange={changeDemoForm}
							/>
						</Col>
						<Col md={12} className="form-col">
							<Input
								type="email"
								value={demoForm.email}
								placeholder="Email address"
								name="email"
								onChange={changeDemoForm}
							/>
						</Col>
						<Col md={12} className="form-col">
							<Input
								type="tel"
								value={demoForm.phone}
								placeholder="Phone number"
								name="phone"
								onChange={changeDemoForm}
							/>
						</Col>
						<Col md={12} className="form-col">
							<Input
								type="text"
								value={demoForm.companyName}
								placeholder="Company name"
								name="companyName"
								onChange={changeDemoForm}
							/>
						</Col>
						<Col md={12} className="form-col">
							<Select
								value={demoForm.type}
								placeholder="Account type"
								onChange={(value) =>
									changeDemoForm({ target: { name: "type", value: value } })
								}
							>
								<Option value="Advisor">Advisor</Option>
								<Option value="Broker">Broker</Option>
								<Option value="Elevator">Elevator</Option>
								<Option value="Feedlot">Feedlot</Option>
								<Option value="Producer">Producer</Option>
								<Option value="Seed Cleaner">Seed Cleaner</Option>
								<Option value="Seed Company">Seed Company</Option>
								<Option value="Other">Other</Option>
							</Select>
						</Col>
						<Col md={24}>
							<TextArea
								rows={4}
								value={demoForm.comment}
								placeholder="Comment (Optional)"
								name="comment"
								onChange={changeDemoForm}
							/>
						</Col>
					</Row>
					<div className="text-center">
						<Button type="primary" htmlType="submit">
							Send Request
						</Button>
					</div>
				</form>
			</div>
		);
	} else if (stage == 5) {
		showBackButton = false;
		modalInner = (
			<div>
				<h2>Thanks!</h2>
				<p className="sub-text">
					Your request has been submitted and we will contact you.
				</p>
				<div className="text-center">
					<Button type="default" htmlType="button" onClick={props.onClose}>
						Close
					</Button>
				</div>
			</div>
		);
	}

	let backButton = <div></div>;
	if (showBackButton) {
		backButton = (
			<Button type="text" className="btn-back" onClick={() => moveStage(1)}>
				<svg
					width="18px"
					height="16px"
					viewBox="0 0 18 16"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
				>
					<g
						id="Page-1"
						stroke="none"
						strokeWidth="1"
						fill="none"
						fillRule="evenodd"
					>
						<g
							id="Download-2"
							transform="translate(-439.000000, -184.000000)"
							fill="#000000"
						>
							<g id="Group-12" transform="translate(416.000000, 155.000000)">
								<g id="Fill" transform="translate(20.000000, 25.000000)">
									<polygon points="10.5 19.5 11.5575 18.4425 5.8725 12.75 21 12.75 21 11.25 5.8725 11.25 11.5575 5.5575 10.5 4.5 3 12"></polygon>
								</g>
							</g>
						</g>
					</g>
				</svg>
			</Button>
		);
	}

	return (
		<div className="modal-overlay" onClick={props.onClose}>
			<div
				className="modal-inner"
				data-stage={stage}
				onClick={(e) => e.stopPropagation()}
			>
				{backButton}
				<Button type="text" className="btn-close" onClick={props.onClose}>
					<svg
						width="12px"
						height="12px"
						viewBox="0 0 12 12"
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						xmlnsXlink="http://www.w3.org/1999/xlink"
					>
						<g
							id="Page-1"
							stroke="none"
							strokeWidth="1"
							fill="none"
							fillRule="evenodd"
						>
							<g
								id="Download"
								transform="translate(-982.000000, -236.000000)"
								fill="#000000"
							>
								<g id="Group-12" transform="translate(416.000000, 205.000000)">
									<g id="Fill" transform="translate(560.000000, 25.000000)">
										<polygon points="18 7.05 16.95 6 12 10.95 7.05 6 6 7.05 10.95 12 6 16.95 7.05 18 12 13.05 16.95 18 18 16.95 13.05 12"></polygon>
									</g>
								</g>
							</g>
						</g>
					</svg>
				</Button>
				{modalInner}
			</div>
		</div>
	);
};

export default DownloadModal;
