import React from 'react';
import arrow from '../../../assets/images/icons/right-arrow.svg';

const pilotStyle = { fontSize: "16px", fontWeight: "600", color: "black" };
const arrowStyle = { marginLeft: "0.8rem" };

export const pilots = [
    {
        title: "",
        subtitle: "Carbon Intensity",
        content: (
            <div>
                <p>Grain Discovery's innovative traceability software revolutionizes the way biofuel processors comply with emerging sustainable standards and premiums (e.g., 45Z). Integrated directly within their user-friendly app,  producers can effortlessly input field-level data, which generates an aggregated Carbon Intensity (CI) scorecard for the field or farm level.  </p>
                <p>Biofuel plants benefit by being able to identify and purchase low-CI corn at a premium price. Simultaneously, it financially rewards farmers who adopt sustainable practices, giving them a tangible incentive to continue reducing their environmental impact. </p>
            </div>
        ),
        anchor: "price-discovery",
        images: ["heat-map.png", "gas-pump.png", "corn-harvest.png"],
        alts: ["A field of barley", "A brewery with a man in a lab coat in the background.", "Corn stalks in a field."],
    },
    {
        title: "",
        subtitle: "Malt Barley",
        content: (
            <div>
                <p>Today, our food passes through many intermediaries on its journey from the farm, and traceability and provenance is often lost. However, consumers are increasingly interested in knowing where their food and drink comes from, how it’s made, and who was involved on the journey. </p>
                <p>We are building a mechanism to answer those questions. Primarily focusing on malt barley, this project will enable the supply chain to capitalize on this market opportunity and build additional value into their product</p>
            </div>
        ),
        anchor: "price-discovery",
        images: ["barley-field.jpg", "brewery.jpg", "beer-barley.jpg"],
        alts: ["A field of barley", "A brewery with a man in a lab coat in the background.", "Corn stalks in a field."],
    },
    {
        title: "",
        subtitle: "Identity Preserved (IP) Soybeans",
        content: (
            <div>
                <p>Our food’s journey from farm to fork involves many steps, and often traceability is lost. But today’s consumer is increasingly interested in knowing where their food comes from, how it's made, and what exactly happens on the trip to their dinner plate. </p>
                <p>This pilot (March/April 2019) provided a mechanism for soybean producers and food processors to capitalise on this demand and build additional value into their product. </p>
                <p><a style={pilotStyle} target="_blank" rel="noopener noreferrer" href="http://id.graindiscovery.com/">Follow the journey<span><img width="15px" src={arrow} style={arrowStyle} alt="right arrow"></img></span></a></p>
                <p><a style={pilotStyle} target="_blank" rel="noopener noreferrer" href="https://mailchi.mp/1e8d9422e93f/media-release-the-next-generation-of-food-traceability">Read the press release<span><img width="15px" src={arrow} style={arrowStyle} alt="right arrow"></img></span></a></p>
            </div>
        ),
        anchor: "traceability",
        images: ["soybeans.jpg", "tofu.png", "bag-soy.jpg"],
        alts: ["A field of soybeans", "A box of packages of tofu", "A man smiing and holding a large brown bag on his shoulder"],
    },

    {
        title: "",
        subtitle: "Field to Glass",
        content: (
            <div>
                <p>This project aims to explore blockchain technology use cases and potential wider adoption in the agricultural industry. Together with our partners (Decisive Farming and Olds College), we will build an end-to-end solution to trace barley seed through the value chain to the final product, beer – all grown and brewed at Olds College!</p>
                <p>This project looks to address consumers growing concerns on food safety and sustainability of the supply chain and allow the supply chain to build additional value into their product. In addition, it also incorporates IoT technology that links agronomic data directly to the permissioned blockchain.</p>
            </div>
        ),
        anchor: "field-to-glass",
        images: ["oldscollege.jpg", "beer.jpg", "hops.jpg"],
        alts: ["A room with large metal barrels with pressure gauges on them.", "A of hops containing with larhe leaves.", "A pile of harvested barley."],
    },
    {
        title: "",
        subtitle: "Northumberland & Prince Edward County",
        content: (
            <div>
                <p>Grain Discovery successfully executed the world’s first corn trade using Blockchain in December of 2018. After multiple rejections of vomitoxin-infected loads of corn, two Prince Edward County farmers, Larry Reynolds and Lloyd Crowe were able to use the Grain Discovery’s online marketplace to find a new local buyer, confirm the trade and receive payment instantly.</p>
                <p>After supplementary technology upgrades, further product testing, analysis and marketing undertaken by the Grain Discovery team, the marketplace will officially launch In North America in late 2019.</p>
                <a style={pilotStyle} target="_blank" rel="noopener noreferrer" href="https://us7.campaign-archive.com/?u=d7bbcb66546cdec062f65fdfc&id=6172117d8a">Read the press release<span><img width="15px" src={arrow} style={arrowStyle} alt="right arrow"></img></span></a>
            </div>
        ),
        anchor: "price-discovery",
        images: ["lloyd-larry.jpg", "silos.jpg", "corn-field.jpg"],
        alts: ["2 men smiling and holding a latop", "A large silo with 3 small silos beside it.", "Corn stalks in a field."],
    },
]