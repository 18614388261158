import React, { useState, useEffect } from 'react';
import { withGoogleMap, GoogleMap, Marker, withScriptjs } from 'react-google-maps';
import { Button } from 'antd';

// components
import Header from '../../components/header/Header';
import SubscribeBar from '../../components/subscribeBar/SubscribeBar';
import DownloadModal from '../../components/downloadModal/DownloadModal';

// assets
import headerImg from '../../assets/images/headers/sunset.jpg'
import './Contact.css';

const Contact = () => {
  const [width, setWidth] = useState(0);
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const [showDemoForm, setShowDemoForm] = useState(false);

  const openDownloadModal = (shouldShowDemo) => {
    setShowDemoForm(shouldShowDemo === true);
    setIsDownloadOpen(true);
  }

  const closeDownloadModal = () => {
    setIsDownloadOpen(false);
  }

  useEffect(() => {
    document.title = "Contact Us — Grain Discovery"
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    return function cleanup() {
      window.removeEventListener('resize', updateWindowDimensions);
    }
  });

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  }


  const PictonMap = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: 44.024687, lng: -77.120361 }}
      defaultOptions={{ fullScreenControl: false, scrollWheel: false }}
    >
      {props.isMarkerShown && <Marker position={{ lat: 44.024687, lng: -77.120361 }} />}
    </GoogleMap>
  ));

  const MAP_KEY = 'AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo';
  const googleMapURL = 'https://maps.googleapis.com/maps/api/js?key=' + MAP_KEY + '&v=3.exp&libraries=geometry,drawing,places'

  return (
    <div className="Contact">
      <Header img={headerImg} alt="A combine working in a field as the sun is going down." />
      <div className="section-block get-in-touch thin-section">
        <h1>Get in touch with us</h1>
        <p>Grain Discovery is about collaboration and delivering benefit to everyone in the food supply chain.</p>
        <p>So if you’re a farmer, potential partner organisation, job seeker or simply interested in hearing about what we’re doing, first. Sign up below or contact us at <a href="mailto:info@graindiscovery.com" target="_blank" rel="noopener noreferrer" >info@graindiscovery.com</a>.</p>
        <p>We can't wait to start the conversation.</p>
      </div>

      <div className="button-container thin-section">
        <Button
          type="primary"
          onClick={() => openDownloadModal(true)}
          size="large"
        >
          Bring Your Business Online
        </Button>
      </div>

      <div className="subscribe-container thin-section">
        <SubscribeBar />
      </div>

      <div className="section-block address">
        <div>
          <div>
            <p className="title">Prince Edward County</p>
            <p>11565 Loyalist Pkwy</p>
            <p>Prince Edward, ON, Canada</p>
            <p>K0K 2T0</p>
          </div>
          <div className="map section">
            <PictonMap
              isMarkerShown
              googleMapURL={googleMapURL}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `300px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
        </div>
      </div>
      <DownloadModal isOpen={isDownloadOpen} onClose={closeDownloadModal} showDemo={showDemoForm} />
    </div>
  );
}

export default Contact;
