export const mockupText = [
    {
        title: "Private",
        content: "Your custom web & app brand",
        img: "Private.png",
        icon: "private.svg",
        iconAlt: "Private"
    },
    {
        title: "Live Market",
        content: "Instantly post bids, track offers, and finalize contracts",
        img: "live_market.png",
        icon: "live_market.png",
        iconAlt: "Live Market"
    },
    {
        title: "Full Portfolio",
        content: "Seamlessly connect to your ERP, enabling producers to access their tickets, contracts, and settlements",
        img: "full_portfolio.png",
        icon: "full_portfolio.svg",
        iconAlt: "Full Portfolio"
    },
    {
        title: "CRM",
        content: "Consolidate notes and buying opportunities in one location",
        img: "CRM.png",
        icon: "grower_crm.svg",
        iconAlt: "Grower CRM"
    },
    {
        title: "Logistics",
        content: "Streamline post-harvest delivery management",
        img: "Logistics.png",
        icon: "logistics.svg",
        iconAlt: "Logistics"
    },
    {
        title: "Traceability",
        content: "Achieve traceability compliance by documenting field agronomic data",
        img: "Traceability.png",
        icon: "argonomic.svg",
        iconAlt: "Agronomics"
    },
    {
        title: "News & Alerts",
        content: "Stay informed with prompt updates and alerts",
        img: "news_alerts.png",
        icon: "news_alerts.svg",
        iconAlt: "News & Alerts"
    },
    {
        title: "Communication",
        content: "Stay in touch with producers through text and push notifications",
        img: "Messages.png",
        icon: "messages.png",
        iconAlt: "Messages"
    },
]
