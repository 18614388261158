import React from 'react';

// assets
import '../../pages/team/Team.css';
import './TeamFormat.css';

const FormatTeam = ({ item, showModal }) => {
    const isEmployee = item.position ? true: false;

    return (
        <div className="Employees"
            onClick={() => { showModal(item, isEmployee) }}>
            <img height="125px" src={require('../../assets/images/headshots/' + item.img)} alt={item.name}></img>
            <p className="name">{item.name}</p>
            {isEmployee && <p className="position">{item.position}</p>}
        </div>
    );
}

export default FormatTeam;