import React, { useState } from 'react';

import ModalVideo from 'react-modal-video'
import img from '../../../assets/images/products/ProductsHeader.png'
import './AccessOurPlatform.scss'
import PlayIcon from "../../svg/PlayIcon";
import { Button } from "antd";

const AccessOurPlatform = () => {
    const [isOpen, setOpen] = useState(false)
    return (
        <div className="section book-section-1">
            <div className="title">
                <h2>Access our platform on the go</h2>
                <p>Available on iOS, Android, and Desktop</p>
            </div>

            <div className="content">
                <div className="img-wrapper">
                    <img src={img} alt="Market Your Grain Your Way" />
                </div>

                <div className="footer">
                    <Button className="btn-primary" onClick={() => setOpen(true)}>
                        <PlayIcon />
                        <span>View Key Features</span>
                    </Button>
                </div>
            </div>

            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="n8CDcUq_-dE" onClose={() => setOpen(false)} />
        </div>
    );

}

export default AccessOurPlatform;
