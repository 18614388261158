import React, { useState, useEffect } from 'react';
import { Layout, Row, Col } from 'antd';
import { Link } from 'react-router-dom';

// assets
import logo from '../../assets/images/logo/GDFull.png';
import './MainLayout.scss';
import SubscribeBar from "../../components/subscribeBar/SubscribeBar";
import FacebookIcon from "../../components/svg/FacebookIcon";
import TwitterIcon from "../../components/svg/TwitterIcon";
import InstagramIcon from "../../components/svg/InstagramIcon";
import DownloadModal from "../../components/downloadModal/DownloadModal";

const MainLayout = ({ children }) => {
  const [width, setWidth] = useState(0);
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const [showDemoForm, setShowDemoForm] = useState(false);

  const openDownloadModal = (shouldShowDemo) => {
    setShowDemoForm(shouldShowDemo === true);
    setIsDownloadOpen(true);
  }

  const closeDownloadModal = () => {
    setIsDownloadOpen(false);
  }

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    return function cleanup() {
      window.removeEventListener('resize', updateWindowDimensions);
    }
  });

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth)
  }

  return (
    <Layout className="bg-white">
      {children}
      <div className="footer bg-4">
        <div className="container footer-container">
          <Row className="footer-up" type={"flex"}>
            <div>
              <img src={logo} alt="Grain Discovery logo" width="200px" />
            </div>
            <div>
              <ul>
                <li>
                  <Link to="#" className='link-title'>About</Link>
                </li>
                <li>
                  <Link to="/press">News</Link>
                </li>
                <li>
                  <Link to="/team">Our Team</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <Link to="#" className='link-title'>Products</Link>
                </li>
                <li>
                  <Link to="/products">Advisor</Link>
                </li>
                <li>
                  <Link to="/products">Broker</Link>
                </li>
                <li>
                  <Link to="/products">Elevator</Link>
                </li>
                <li>
                  <Link to="/products">Feedlot</Link>
                </li>
                <li>
                  <Link to="/products">Seed Cleaner</Link>
                </li>
                <li>
                  <Link to="/products">Seed Retailer</Link>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <Link className='link-title' to="/saygday">Contact Us</Link>
                </li>
                <li>
                  <Link onClick={() => openDownloadModal(true)}>Request a demo</Link>
                </li>
                <li>
                  <Link to="/saygday">Contact Us</Link>
                </li>
              </ul>
              <SubscribeBar />
              <ul style={{ display: 'flex' }}>
                <li>
                  <a href="https://facebook.com/graindiscovery" target="_blank">
                    <FacebookIcon />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/@graindiscovery" target="_blank">
                    <TwitterIcon />
                  </a>
                </li>
                <li>
                  <a href="https://instagram.com/grain_discovery" target="_blank">
                    <InstagramIcon />
                  </a>
                </li>
              </ul>
            </div>
          </Row>
          <div className="copyright">
            <p>© {new Date().getFullYear()} Grain Discovery. All rights reserved.</p>
          </div>
        </div>
      </div>
      <DownloadModal isOpen={isDownloadOpen} onClose={closeDownloadModal} showDemo={showDemoForm} />

    </Layout>
  );
}

export default MainLayout;
