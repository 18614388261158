import React, { useState } from 'react';

import LiveMarket from '../../../assets/images/products/LiveMarket.png'
import CRM from '../../../assets/images/products/CRM.png'
import Portfolio from '../../../assets/images/products/Portfolio.png'
import News from '../../../assets/images/products/News.png'
import TradeCreation from '../../../assets/images/products/TradeCreation.png'
import Traceability from '../../../assets/images/products/Traceability.png'
import ClientCarousel from '../../home/clientCarousel/ClientCarousel'
import './Products.scss'
import { Button, Col, Row } from "antd";
import PlayIcon from "../../svg/PlayIcon";
import ModalVideo from "react-modal-video";

const Products = () => {
    const [isOpen, setOpen] = useState(false)
    const [videoId, setVideoId] = useState('')

    const playVideo = (videoId) => {
        setVideoId(videoId);
        setOpen(true);
    }

    return (
        <div className="book-section-2">
            <Row type={"flex"} align={"middle"} className="book-content">
                <Col span={24} lg={{ span: 12 }}>
                    <div className="img-container left">
                        <img src={LiveMarket} alt="Left Ribbon" />
                    </div>
                </Col>
                <Col span={24} lg={{ span: 12 }}>
                    <div className="title text-container">
                        <h2>Live Market</h2>
                        <p>
                            Digitize and simplify by creating live bids for your farmers and viewing their offers, confirming deals instantly
                        </p>
                        <Button onClick={() => playVideo("wwtI52eafdA")}
                            className="btn-primary">
                            <PlayIcon />
                            <span>Play Video</span>
                        </Button>
                    </div>
                </Col>
            </Row>

            <Row type={"flex"} align={"middle"} className="book-content">
                <Col span={24} lg={{ span: 12 }} order={1}>
                    <div className="title text-container right">
                        <h2>Trade Creation</h2>
                        <p>
                            Farmers can easily create new offers (delivered or FOB)
                        </p>
                        <Button className="btn-primary"
                            onClick={() => playVideo("pgIJy3-ndqs")} >
                            <PlayIcon />
                            <span>Play Video</span>
                        </Button>
                    </div>
                </Col>
                <Col span={24} lg={{ span: 12, order: 2 }} order={0}>
                    <div className="img-container right">
                        <img src={TradeCreation} alt="Left Ribbon" />
                    </div>
                </Col>
            </Row>

            <Row type={"flex"} align={"middle"}>
                <Col span={24} lg={{ span: 12 }}>
                    <div className="img-container left">
                        <img src={Portfolio} alt="Left Ribbon" />
                    </div>
                </Col>
                <Col span={24} lg={{ span: 12 }}>
                    <div className="title text-container">
                        <h2>Portfolio</h2>
                        <p>
                            View historical and recent scale tickets, contracts and settlements anytime, anywhere
                        </p>
                        <Button
                            onClick={() => playVideo("OrsENPvUXDQ")}
                            className="btn-primary">
                            <PlayIcon />
                            <span>Play Video</span>
                        </Button>
                    </div>
                </Col>
            </Row>

            <Row type={"flex"} align={"middle"} className="book-content">
                <Col span={24} lg={{ span: 12 }} order={1}>
                    <div className="title text-container right">
                        <h2>Traceability</h2>
                        <p>
                            Achieve traceability compliance by documenting field agronomic data
                        </p>
                        {/* <Button className="btn-primary"
                            onClick={() => playVideo("pgIJy3-ndqs")} >
                            <PlayIcon />
                            <span>Play Video</span>
                        </Button> */}
                    </div>
                </Col>
                <Col span={24} lg={{ span: 12, order: 2 }} order={0}>
                    <div className="img-container right">
                        <img src={Traceability} alt="Left Ribbon" />
                    </div>
                </Col>
            </Row>

            <Row type={"flex"} align={"middle"}>
                <Col span={24} lg={{ span: 12 }}>
                    <div className="img-container left">
                        <img src={CRM} alt="Left Ribbon" />
                    </div>
                </Col>
                <Col span={24} lg={{ span: 12 }}>
                    <div className="title text-container">
                        <h2>CRM</h2>
                        <p>
                            Consolidate producer notes, reminders and buying opportunities  in one location
                        </p>
                        {/* <Button
                            onClick={() => playVideo("OrsENPvUXDQ")}
                            className="btn-primary">
                            <PlayIcon />
                            <span>Play Video</span>
                        </Button> */}
                    </div>
                </Col>
            </Row>

            <Row type={"flex"} align={"middle"} className="book-content">
                <Col span={24} lg={{ span: 12 }} order={1}>
                    <div className="title text-container right">
                        <h2>News & Alerts</h2>
                        <p>
                            Stay informed with updates and latest market news
                        </p>
                        {/* <Button className="btn-primary"
                            onClick={() => playVideo("pgIJy3-ndqs")} >
                            <PlayIcon />
                            <span>Play Video</span>
                        </Button> */}
                    </div>
                </Col>
                <Col span={24} lg={{ span: 12, order: 2 }} order={0}>
                    <div className="img-container right">
                        <img src={News} alt="Left Ribbon" />
                    </div>
                </Col>
            </Row>

            <ClientCarousel />
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={videoId} onClose={() => setOpen(false)} />
        </div>
    );

}

export default Products;
