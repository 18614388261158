import React from 'react';
import {Button} from 'antd';

export const openings = [
    {
        // title: "Full Stack Web Developer",
        // location: "Toronto, Ontario",
        // duration: "Full Time",
        // link: "full-stack-web-developer",
        // content: (
        //     <div>
        //         <h2>About Us</h2>
        //         <p>Grain Discovery is a cutting-edge technology company aiming to revolutionize the agricultural industry. We are changing the way how the agricultural supply chain networks and how consumers regain trust and confidence in their food origination story by building a digital ecosystem focusing on price discovery and traceability. The founders have many years of experience in the agricultural and financial markets as traders and quants managing portfolios in the billions of dollars and recognized the potential of blockchain early.</p>
        //         <p>In addition to the marketplace, our goal in 2020 is to expand on our prior world first blockchain pilots and build an end to end digital solution to track commodities through the value chain, providing certification/quality assurances, and provenance data. Modern agriculture is undergoing an important shift: away from a commodities business, where each bushel is considered identical, to an ingredients business, where customers demand distinction. Grain Discovery is capitalizing on these changing industry requirements and believe blockchian is the ideal technology enabler for data connection in revealing our foods “digital passport”. </p>
        //         <p>We have recently finalized the design and implementation of our online marketplace and are looking for a designer to implement customer feedback and develop new product designs. </p>
        //
        //         <h2>About You</h2>
        //         <p>As an early stage startup, we are looking for people who want more than to be an employee completing assigned tasks. We are looking for entrepreneurial people who are curious and ambitious with a passion to help grow a company from seed to exit! This is an opportunity to be involved in strategy and blue-sky thinking and as such will require both flexibility and creativity as the job evolves in pace with the company itself.</p>
        //         <p>Competitive salary.</p>
        //
        //         <h2>Requirements</h2>
        //         <ul>
        //             <li>Working with core team to manage and support applications.</li>
        //             <li>Ability to take initiative to research, learn and recommend emerging technologies</li>
        //             <li>Utilize in-depth specialty knowledge of applications development to analyze complex problems/issues, provide evaluation of business process, system process, and industry standards, and make evaluative judgement</li>
        //             <li>Recommend and develop security measures in post implementation analysis of business usage to ensure successful system design and functionality</li>
        //             <li>Participate in the design process</li>
        //         </ul>
        //
        //         <h2>Qualifications</h2>
        //         <ul>
        //             <li>Fluency in web development technologies and good understanding of the limitations</li>
        //             <li>3+ years of experience developing web applications with React + Redux Sagas</li>
        //             <li>3+ years of working with Express.js</li>
        //             <li>2+ years of experience working with RestFul API </li>
        //             <li>2+ years of experience with RDBMS </li>
        //             <li>2+ years of experience working with Redis</li>
        //             <li>1+ years of working with GraphQL API </li>
        //             <li>Strong understanding of software engineering best practices</li>
        //         </ul>
        //
        //         <h3 style={{fontSize: '30px'}}>Great to Have</h3>
        //         <ul>
        //             <li>Knowledge of blockchain technology particularly Hyperledger Fabric </li>
        //             <li>Java/Kotlin</li>
        //         </ul>
        //
        //         <Button type="primary" shape="round" size="large" style={{marginTop: '2rem'}} href={"https://angel.co/l/2tX7yr"}>
        //             Apply Now
        //         </Button>
        //
        //     </div>

    }
    // {
    //     title: "Mobile Developer",
    //     location: "Toronto, Ontario",
    //     duration: "Full time",
    //     link: "mobile-developer",
    //     content: (
    //         <div>
    //             <h2>About Us</h2>
    //             <p>Grain Discovery is a cutting-edge technology company aiming to revolutionize the agricultural industry. We are changing the way how the agricultural supply chain networks and how consumers regain trust and confidence in their food origination story by building a digital ecosystem focusing on price discovery and traceability. The founders have many years of experience in the agricultural and financial markets as traders and quants managing portfolios in the billions of dollars and recognized the potential of blockchain early.</p>
    //             <p>In addition to the marketplace, our goal in 2020 is to expand on our prior world first blockchain pilots and build an end to end digital solution to track commodities through the value chain, providing certification/quality assurances, and provenance data. Modern agriculture is undergoing an important shift: away from a commodities business, where each bushel is considered identical, to an ingredients business, where customers demand distinction. Grain Discovery is capitalizing on these changing industry requirements and believe blockchian is the ideal technology enabler for data connection in revealing our foods “digital passport”. </p>
    //             <p>We have recently finalized the design and implementation of our online marketplace and are looking for a designer to implement customer feedback and develop new product designs. </p>
    //
    //             <h2>About You</h2>
    //             <p>As an early stage startup, we are looking for people who want more than to be an employee completing assigned tasks. We are looking for entrepreneurial people who are curious and ambitious with a passion to help grow a company from seed to exit! This is an opportunity to be involved in strategy and blue-sky thinking and as such will require both flexibility and creativity as the job evolves in pace with the company itself. The size of our addressable market is enormous, so the potential upside is considerable.</p>
    //             <p>Competitive salary, with equity.</p>
    //
    //             <h2>Requirements</h2>
    //             <ul>
    //                 <li>Working with core team to manage and support applications.</li>
    //                 <li>Ability to take initiative to research, learn and recommend emerging technologies</li>
    //                 <li>Utilize in-depth specialty knowledge of applications development to analyze complex problems/issues, provide evaluation of business process, system process, and industry standards, and make evaluative judgement</li>
    //                 <li>Recommend and develop security measures in post implementation analysis of business usage to ensure successful system design and functionality</li>
    //                 <li>Participate in the design process</li>
    //                 <li>Provide accurate estimates</li>
    //             </ul>
    //
    //             <h2>Qualifications</h2>
    //             <ul>
    //                 <li>Fluency in mobile app development technologies and good understanding of the limitations</li>
    //                 <li>2+ years of experience developing mobile applications with Swift and/or flutter</li>
    //                 <li>2+ years of experience working with RestFul API</li>
    //                 <li>2+ years of experience with RDBMS</li>
    //                 <li>1+ years of working with GraphQL API</li>
    //                 <li>Strong understanding of software engineering best practices </li>
    //             </ul>
    //
    //             <h2>Competencies</h2>
    //             <ul>
    //                 <li>Strong communication skills</li>
    //                 <li>Excellent planning and organizational skills</li>
    //                 <li>Self-Motivated</li>
    //                 <li>Willingness to learn new tools and technology and work in a collaborative environment</li>
    //                 <li>Creativity, this is an opportunity to be involving in shaping the strategy of our company. Ideas and input at all levels in the business is welcome.</li>
    //             </ul>
    //
    //             <h3 style={{fontSize: '30px'}}>Great to Have</h3>
    //             <ul>
    //                 <li>Knowledge of blockchain technology particularly Hyperledger Fabric </li>
    //                 <li>React and Node.js</li>
    //                 <li>Java/Kotlin</li>
    //             </ul>
    //
    //             <Button type="primary" shape="round" size="large" style={{marginTop: '2rem'}} href={"https://angel.co/l/2tXsmG"}>
    //                 Apply Now
    //             </Button>
    //         </div>
    //     ),
    // }
]
