import React from 'react';
import {Row, Col} from 'antd';
import './ImageGrid.css';

const ImageGrid = ({pilot}) => {
    const { images, alts } = pilot;
    return (
      <div className="ImageGrid">
          <Row className="top-row">
            <img src={require('../../assets/images/imageGrids/' + images[0])} alt={alts[0]}  height="250px" width="100%"></img>
          </Row>
          <Row>
              <Col span={14} >
                <img 
                  className="left-image"
                  src={require('../../assets/images/imageGrids/' + images[1])} alt={alts[1]} height="150px" width="100%"></img>
              </Col>
              <Col span={10}>
              <img 
                className="right-image"
                src={require('../../assets/images/imageGrids/' + images[2])} alt={alts[2]} height="150px" width="100%"></img>
              </Col>
          </Row>
      </div>

    );
}

export default ImageGrid;