import React, {useState} from 'react';
import ReactPlayer from 'react-player'

//assets
import './WhatWeDo.scss'
import img from '../../../assets/images/home/video_thumbnail.png';
import ribbonImg from '../../../assets/images/home/yellowRibbons.png';
import {Button} from "antd";
import PlayIcon from "../../svg/PlayIcon";

const WhatWeDo = () => {
  const [youtubeVisible, setYoutubeVisible] = useState(false);
  const [playing, setPlaying] = useState(false);
  const opts = {
    height: '467',
    width: '830',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
    },
  };

  const playVideo = () => {
    setYoutubeVisible(!youtubeVisible);
    setPlaying(!playing);
  }

    return (
        <div className="home-section-1">
            <div className="title">
                <h2>What we do</h2>
                <p>We're benefiting everyone in the food supply chain, from farmers, to brokers, traders, exporters, and buyers</p>
            </div>
            <div className="content">
              <div className="video-container">
                <div className="ribbon-img-wrapper">
                  <img src={ribbonImg} alt="Background Ribbon" className="ribbon-img" />
                </div>
                <div className={`thumbnail-container ${!youtubeVisible ? 'hidden' : ''}`}>
                  <img src={img} alt="Video Thumbnail" onClick={playVideo} />
                  <Button className="btn-play" onClick={playVideo}>
                    <PlayIcon />
                  </Button>
                </div>

                <div className={`youtube-container ${youtubeVisible ? 'hidden' : ''}`}>
                  <ReactPlayer
                    url="https://www.youtube.com/watch?v=p9maVRsOews"
                    playing={true}
                    light={true}
                    className={"youtube-player"}
                    width={'100%'}
                    height={'100%'} />
                </div>

              </div>
            </div>

        </div>
    );
}

export default WhatWeDo;
