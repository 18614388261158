import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';

// components
import Header from '../../components/header/Header'
import ImageGrid from '../../layouts/imageGrids/ImageGrid';
import { pilots } from '../../data/pages/pilots/pilotsInfo';

// assets
import headerImg from '../../assets/images/headers/rake.jpg';
import './Pilots.css';

const Pilots = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    document.title = "Our Work — Grain Discovery"

    return function cleanup() {
      window.removeEventListener('resize', updateWindowDimensions);
    }
  });

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  }

  return (
    <div className="Pilots">
      <Header img={headerImg} alt="A closeup photo of a wheel rake with the sky in the background" />

      <div className="section-block thin-section">
        <h1>Our work</h1>
        <p>Check out our previous pilot projects</p>
      </div>

      {pilots.map((pilot, index) => {
        return (
          <div className="section-block pilot-info" key={index} id={pilot.anchor}>
            <h2>{pilot.title}</h2>
            <Row >
              <Col span={width < 1000 ? 25 : 12}>

                <h3>{pilot.subtitle}</h3>
                {pilot.content}
              </Col>

              {width >= 1000 &&
                <Col span={2}>
                </Col>
              }

              <Col span={width < 1000 ? 25 : 10}>
                <ImageGrid pilot={pilot} />
              </Col>
            </Row>
          </div>
        )
      })}

    </div>
  );
}

export default Pilots;