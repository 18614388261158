import React from "react";

const RightArrowIcon = () => {
  return (
      <svg width="19px" height="15px" viewBox="0 0 19 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Actions / Navigation / arrow--right / 24</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Home" transform="translate(-764.000000, -3963.000000)" fill="#000000">
            <g id="Group-3" transform="translate(467.000000, 3859.000000)">
              <g id="Fill" transform="translate(294.500000, 99.500000)">
                <polygon points="13.5 4.5 12.4275 5.54475 18.1125 11.25 3 11.25 3 12.75 18.1125 12.75 12.4275 18.42975 13.5 19.5 21 12" />
              </g>
            </g>
          </g>
        </g>
      </svg>
  );
}

export default RightArrowIcon;
