import React, { useState, useRef } from 'react';
import { Row, Col, Button } from 'antd';
import Slider from "react-slick";

import './InteractiveMockup.scss'

import { mockupText } from '../../../data/components/home/interactiveMockup/mockupText';

const InteractiveMockup = ({ openDownloadModal }) => {

    const [hoverImage, setHoverImage] = useState(mockupText[0].img);
    const [activeIndex, setActiveIndex] = useState(0);
    const sliderRef = useRef();

    const settings = {
        className: "center",
        centerMode: true,
        centerPadding: "20px",
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: current => {
            setHoverImage(mockupText[current] ? mockupText[current].img : mockupText[0].img)
            setActiveIndex(current)
        }
    };

    const handleMouseEnter = (feature, index) => {
        setHoverImage(feature.img)
        setActiveIndex(index)
    }

    const mockUpCard = (feature, index, isLeft) => {
        return (
            <div
                className={`mockup-card ${isLeft ? '' : 'right'} ${activeIndex === index ? 'active' : ''}`} onMouseEnter={() => handleMouseEnter(feature, index)} >
                <div>
                    <h3>{feature.title}</h3>
                    <p>{feature.content}</p>
                </div>
                <img src={require(`../../../assets/images/home/interactiveMockup/icons/${feature.icon}`)} alt={feature.iconAlt} />
            </div>
        )
    }

    return (
        <div className="home-section-2 container">
            <div className="title">
                <h2>Simplify Your Business Now</h2>
                <p>Get a personalized origination, traceability and producer management system. Built for growth, Grain Discovery serves Elevators, Processors, Seeds, Feedlots, Brokers, and Grain Advisors</p>
            </div>

            <div className="content">
                <Row align={"middle"} type={"flex"}>
                    <Col span={24} xl={6} order={1} className="left mockup-wrapper">
                        {mockupText.slice(0, 4).map((feature, index) => {
                            return (
                                <div key={index}>
                                    {mockUpCard(feature, index, true)}
                                </div>
                            );
                        })}
                    </Col>
                    <Col span={24} xl={{ span: 12, order: 2 }} className="mockup-img-wrapper" order={0}>
                        <img src={require(`../../../assets/images/home/interactiveMockup/${hoverImage}`)} alt="A laptop showing the Grain Discovery marketplace" />
                    </Col>
                    <Col span={24} xl={6} order={3} className="right mockup-wrapper">
                        {mockupText.slice(4, 8).map((feature, index) => {
                            return (
                                <div key={index}>
                                    {mockUpCard(feature, index + 4, false)}
                                </div>
                            );
                        })}
                    </Col>
                </Row>

                <Slider {...settings} className="slick-container" ref={sliderRef}>
                    {mockupText.map((feature, index) => {
                        return (
                            <div key={index} className="slick-mockup"
                                onClick={() => sliderRef.current.slickGoTo(index)}>
                                {mockUpCard(feature, index, true)}
                            </div>
                        );
                    })}
                </Slider>

                <div className="footer">
                    <Button
                        type="primary"
                        onClick={() => openDownloadModal(true)}
                        size="large"
                    >
                        Bring Your Business Online
                    </Button>
                </div>
            </div>
        </div>
    );

}

export default InteractiveMockup;
