import React, { useEffect, useState } from 'react';

// components
import WhatWeDo from "../../components/home/whatWeDo/WhatWeDo";
import InteractiveMockup from '../../components/home/interactiveMockup/InteractiveMockup';
import HomePageNews from '../../components/home/homePageNews/HomePageNews';
import MarketYourGrain from "../../components/home/marketYourGrain/MarketYourGrain";
import DownloadModal from "../../components/downloadModal/DownloadModal";
import ClientCarousel from "../../components/home/clientCarousel/ClientCarousel";
import AnimatedLogo from "../../components/animatedLogo/AnimatedLogo";

//assets
import './Home.scss';
import img from '../../assets/images/headers/sunrise.png';

const Home = () => {

  useEffect(() => {
    document.title = "Grain Discovery"
  }, []);

  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const [showDemoForm, setShowDemoForm] = useState(false);

  const openDownloadModal = (shouldShowDemo) => {
    setShowDemoForm(shouldShowDemo === true);
    setIsDownloadOpen(true);
  }

  const closeDownloadModal = () => {
    setIsDownloadOpen(false);
  }

  return (
    <div className="home">
      <div className="banner" style={{ backgroundImage: `url(${img})` }}>
        <div className="banner-container">
          <div className="title-container">
            <div className="banner-logo">
              <AnimatedLogo delay={1000} />
            </div>
            <h1 className="title">Welcome to the <span>Future of Agriculture</span></h1>
          </div>
        </div>
      </div>

      <div className="container-fluid section bg-1">
        <InteractiveMockup openDownloadModal={openDownloadModal} />
      </div>

      <div className="container thin-section">
        <ClientCarousel />
      </div>

      <div className="container thin-section">
        <WhatWeDo />
      </div>

      <div className="container thin-section">
        <MarketYourGrain openDownloadModal={openDownloadModal} />
      </div>

      <div className="section thin-section">
        <HomePageNews />
      </div>
      <DownloadModal isOpen={isDownloadOpen} onClose={closeDownloadModal} showDemo={showDemoForm} />
    </div>
  );

}


export default Home;
