import React from 'react';
import {Row, Col} from 'antd';
import './GridLayout.css';

const GridLayout = ({numCols, gutter, data}) => {

    const formatData = (data) => {
        let numRows;
        let formatted = [];
        let counter = 0;

        if(data.length%numCols===0) {
            numRows= data.length/numCols;
        }
        else numRows = Math.ceil(data.length / numCols);


        for(let i=0;i<numRows;i++) {
            formatted.push(
            <Row gutter={gutter && gutter} key={i}>
                {data.slice(counter,counter+(counter+numCols<data.length ? numCols : data.length-counter)).map((el, index)=> {
                    return(
                        <Col span={24/numCols} key={index}>
                            {el}
                        </Col>
                        
                    );
                })}
            </Row>
            );
            counter+=numCols;
        }
        return formatted;
    }

    return (
        <div className="GridLayout">
            {formatData(data)}
        </div>
    );
}

export default GridLayout;