import React, { useEffect } from 'react';

// components
import Header from '../../components/header/Header';

// assets
import headerImg from '../../assets/images/headers/round-field.jpg';
import './Careers.css';

const Careers = () => {
  useEffect(() => {
    document.title = "Careers — Grain Discovery";
  });

  return (
    <div className="Careers">
      <Header img={headerImg} alt="A field of cut wheat with round straw bales in it" />

      <div className="section-block thin-section">
        <h1>Careers</h1>
        <div className="careers-blurb">
          <p>We’re looking for smart, passionate, and entrepreneurial people to help us on our mission of building the
            future of the food supply chain. If you want to make an impact in a workplace that’s exciting, challenging,
            and diverse, we’d love to have you on board.
          </p>
        </div>
      </div>

      <div className="section-block openings thin-section">
        <h2>Interested in working with us?  Send us an <a href='mailto:info@graindiscovery.com'>email</a></h2>
      </div>
    </div>
  );
}

export default Careers;