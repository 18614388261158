import React from "react";

const TwitterIcon = () => {
    return (
        <svg width="45px" height="45px" viewBox="0 0 23 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>Twitter</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Home" transform="translate(-528.000000, -4247.000000)" fillRule="nonzero">
                    <g id="Group-11-Copy-3" transform="translate(-1.000000, 4124.000000)">
                        <g id="Twitter" transform="translate(529.000000, 123.000000)">
                            <path d="M0,11.5 C0,5.14872507 5.14872507,0 11.5,0 C17.8512749,0 23,5.14872507 23,11.5 C23,17.8512749 17.8512749,23 11.5,23 C5.14872507,23 0,17.8512749 0,11.5 Z" id="Combined-Shape" fill="#F4C157" />
                            <path d="M11.1555763,9.34734406 L11.1797083,9.74527001 L10.7775077,9.69654436 C9.31349745,9.50976281 8.0344995,8.87632966 6.94855783,7.81248682 L6.41765301,7.2846259 L6.28090482,7.67443089 C5.99132037,8.54337124 6.17633264,9.46103716 6.77963356,10.0782284 C7.10139406,10.4193078 7.02899795,10.4680334 6.47396111,10.26501 C6.28090482,10.2000425 6.11198056,10.1513169 6.09589252,10.1756797 C6.03958445,10.2325262 6.23264074,10.9715316 6.38547697,11.2638853 C6.59462129,11.6699322 7.02095393,12.0678582 7.48750667,12.3033654 L7.88166327,12.4901469 L7.41511053,12.4982679 C6.96464587,12.4982679 6.94855783,12.5063888 6.99682191,12.6769285 C7.15770216,13.2047894 7.79317913,13.7651341 8.5010522,14.0087623 L8.99978098,14.1793019 L8.56540432,14.439172 C7.92188333,14.8127351 7.16574618,15.0238795 6.40960903,15.0401213 C6.04762847,15.0482423 5.75,15.080726 5.75,15.1050888 C5.75,15.1862982 6.73136948,15.6410707 7.30249437,15.8197314 C9.01586898,16.3475923 11.0510041,16.120206 12.5793664,15.2187819 C13.6653081,14.5772279 14.7512498,13.3022407 15.2580225,12.0678582 C15.5315189,11.4100622 15.8050154,10.2081634 15.8050154,9.63157687 C15.8050154,9.25801374 15.8291474,9.20928813 16.2796121,8.76263652 C16.5450645,8.50276654 16.7944289,8.21853373 16.842693,8.13732433 C16.9231331,7.98302653 16.9150891,7.98302653 16.5048444,8.12108246 C15.8211034,8.36471061 15.7245753,8.33222683 16.0624238,7.96678466 C16.3117881,7.70691464 16.6094166,7.23590028 16.6094166,7.09784431 C16.6094166,7.07348153 16.4887564,7.11408619 16.3520082,7.18717463 C16.207216,7.26838402 15.8854555,7.39019808 15.6441351,7.46328652 L15.2097584,7.60134245 L14.8156018,7.33335151 C14.5984135,7.18717463 14.292741,7.02475587 14.1318608,6.97603026 C13.7216162,6.86233712 13.0941832,6.87857899 12.7241587,7.008514 C11.7186571,7.37395621 11.0831802,8.31598496 11.1555763,9.34734406 Z" id="Combined-Shape-path" fill="#FFFFFF" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default TwitterIcon;
