export const team = [
	{
		name: "Rory O'Sullivan",
		position: "Founder, Chief Executive Officer",
		content:
			"Rory has held a diverse range of roles in the agricultural space over the past 18 years, from cleaning field bins on the family farm at Patchewollock to running a profitable commodity trading book at one of the world’s biggest funds. Travelling and witnessing inefficiencies and opaque supply chains cemented his vision for Grain Discovery.",
		education:
			"Rory studied Agricultural Science at the University of Melbourne.",
		img: "rory.jpg",
		linkedIn: "https://www.linkedin.com/in/rory-o-sullivan-421a8027/",
	},
	{
		name: "Ruairi Hanafin",
		position: "Founder",
		content:
			"Ruairi is a long time blockchain advocate and sees its potential in increasing social fairness and greater financial inclusion- particularly in rural regions. He has held positions in quantitative investments, researched alpha strategies, and headed the blockchain group at one of the world’s largest funds.",
		education:
			"Ruairi holds a PhD in Theoretical Physics from Trinity College from the University of Dublin.",
		img: "ruairi.png",
		linkedIn: "https://www.linkedin.com/in/ruairi-h-06387222/",
	},
	{
		name: "Peter Vincent",
		position: "Founder",
		content:
			"Peter has over 20 years experience in the commodities markets as a trader and entrepreneur. He has coded trading desk infrastructure and set up risk management systems for numerous commodities shops including his own boutique firm. His passion is data and he specializes in building intuitive visualizations and models for complex data sets.",
		education:
			"Peter holds a Master’s Degree in Engineering Physics from Queen’s University.",
		img: "pete.jpg",
	},
	{
		name: "Pete Oram",
		position: "Chief Operating Officer",
		content:
			"Pete has spent his 10 year career in the agriculture industry in Ontario, holding a variety of roles with both Cargill and Sollio Grain in their Grain and Crop Inputs businesses. Pete enjoys identifying and creating new commercial opportunities that fill an important need to customers and bringing them to market.",
		education:
			"Pete possesses a Master of Business Administration from the Rotman School of Management at the University of Toronto",
		img: "peteO.png",
		linkedIn: "https://www.linkedin.com/in/pete-oram-mba-05912314/",
	},
	{
		name: "Rui Pan",
		position: "Engineering Manager",
		content:
			"Rui has been focusing on distributed ledger technologies and applications in supply chain and carbon market since he graduated from university. He has developed a strong interest in full cycle development through his exploration in innovative technologies and product design.",
		education:
			"Rui received his Master of Science degree in Control Systems, Electrical Engineering from University of Alberta.",
		img: "rui.jpg",
	},
	{
		name: "Alison Matthews",
		position: "Public Relations",
		content:
			"Alison is a seasoned public affairs consultant with over a decade of experience working in the agriculture, cleantech and environmental space. A proven track record in digital media, public affairs, communications and government relations, former clients include: Conservative Party of Canada, Sustainable Development Technology Canada Renewable Industries Canada.",
		education:
			"Alison holds a Bachelor of Arts in Classical Studies from the University of Ottawa",
		img: "ali.jpg",
	},
	{
		name: "Logan Kenelly",
		position: "Business Development",
		content:
			"Logan grew up on a dairy farm which led to studying agricultural business, and pursuing a career in the industry. Through the University of Guelph’s Co-op program, he has gained experience and insight into different aspects of the agriculture industry through previous positions with AGDATA and OMAFRA. Logan is excited for the opportunity to build on this experience at Grain Discovery.\n",
		education:
			"Bachelor of Commerce, Food and Agricultural Business, University of Guelph",
		img: "logan.jpg",
	},
	{
		name: "Jesse Ward",
		position: "Western Canada Business Lead",
		content: `Jesse grew up on a farm outside of Three Hills, AB and has been driving tractors almost since birth. With more than 15 years of working in this sector, Jesse has an extensive knowledge of arable farming along with some livestock experience. He is passionate about seeing innovation and improvement in grain farming, and loves the family culture that is deeply rooted in agriculture.`,
		education:
			"Jesse holds an MBA from the Haskayne School of Business at the University of Calgary with a specialization in Innovation and Entrepreneurship.",
		img: "jesse.png",
	},
	{
		name: "Luis Bonilla",
		position: "Design Lead / Mobile Developer",
		content: `Luis joins us from the Dominican Republic and plays a major part as our junior mobile developer! Luis worked previously in the consumer goods and finance sector. Luis loves innovation and helping businesses grow with aid from emerging technologies. Luis wants to help solve the burning problems within agriculture and hopes to bring a wave of technology and innovation to the farming sector!`,
		education:
			"Luis has a Bachelor Bachelor of Information Technology from Unicaribe University",
		img: "luis.png",
	},
	{
		name: "Navdeep Singh Bedi",
		position: "Backend Developer",
		content: `For the past ten years, Navdeep has focused on mobile applications as a software developer. He specializes in creating complicated designs and user interfaces, as well as working with cutting-edge technologies as like cloud messaging, location tracking, geofencing, multi-touch, object recognition. In past, he worked to develop efficient applications for the agricultural industry.`,
		education:
			"Navdeep received his Bachelor of Engineer degree in IT from VTU, India and a graduate certificate in Business Analytics from Seneca College.",
		img: "navdeep.png",
	},
	{
		name: "Yasser Soofiyani",
		position: "Flutter Developer",
		content: `Yasser is a highly skilled IT expert with hands-on experience building native mobile applications. Yasser is a major part of the features and functions that we have all come to love with Grain Discovery. When a problem is identified Yasser will be the person behind the scenes working diligently to ensure you discover the best experience yet. Yasser is passionate about bringing technology and innovation to the agriculture sector, hoping to bring greater transparency to the foods we eat and the processes we work with everyday!`,
		education: "",
		img: "yasser.png",
	},
	{
		name: "Paul Briar",
		position: "Frontend Developer",
		content: `Born and raised in White Rock, BC and Paul is a web developer with experience in front and backend technologies.  His primary focus has been building data driven web applications with React and his work has been used by millions around the world. Paul is part of the ever-present and diligent team of developers at Grain Discovery. Our development team creates the safest, most efficient and simple features and user experience one can have!`,
		education: "",
		img: "paul.png",
	},
];

export const coops = [
	{
		name: "Reed Rogers",
		position: "Software Developer Intern",
		content:
			"Reed is pursuing a degree in Management Engineering from the University of Waterloo. Hailing from a family farm on the Northern Bruce Peninsula, he is excited to combine his degree with his farming background at Grain Discovery! Reed is interested in efficiently solving meaningful technical problems, and theatre, having written and directed the Waterloo Engineering play last term.",
		education: "Bachelor of Management Engineering, University of Waterloo.",
		img: "reed.jpg",
	},
	{
		name: "Ryan Duan",
		position: "Software Developer Intern",
		content:
			"Ryan believes in blockchain's ability to increase transparency and equality in transacting peer-to peer, as well as shine a light on the opacity of products moving through the supply chain. His past projects include work with Hyperledger and other blockchain technologies. His interests include capital markets and full stack web development.",
		education: "Bachelor of Computer Science, Carleton University.",
		img: "ryan.jpg",
	},
	{
		name: "Andrew Lamb",
		position: "Software Developer Intern",
		content:
			"Andrew is a software Engineer with experience working at a number of different startups around Toronto. Background in various web development technologies, both front-end and back-end. Believes in the importance of transparency and equal opportunity for the free market.",
		education: "Bachelor of Software Engineering, University of Waterloo",
		img: "andrew.png",
	},
	{
		name: "Michael Vytlingam",
		position: "Software Developer Intern",
		content:
			"With a background in web development, Michael is a passionate problem solver with a keen interest in experimenting with new technologies, such as blockchain and machine learning. Takes pride in using new technology to solve the world's socioeconomic difficulties and recognizes the importance of transparency in today's world.",
		education: "Bachelor of Mechanical Engineering, University of Waterloo",
		img: "michael.jpg",
	},
	{
		name: "Aishmit Khurana",
		position: "Software Developer Intern",
		content:
			"Enjoys building things, whether it be making music, programming, or even writing. With a passion for travelling, Aishmit has seen first hand the many hardships local farmers face when it comes to distribution of their yields and strongly believes in the company’s vision to revolutionize the industry.",
		education: "Bachelor of Science in Kinesiology, University of Waterloo",
		img: "aishmit.jpg",
	},
];

export const advisors = [
	//GROUPS OF TWO
	{
		name: "The Honourable Lyle Vanclief",
		content:
			"Has been an active member of the agriculture industry for over 50 years. After farming for 25 years he was elected to the Canadian Federal Parliament. He retired from politics (elected four times) as the Federal Minister of Agricultural and Agri-Food. Lyle has been recognised with a Fellowship of the Agricultural Institute of Canada and inducted into the Canadian agricultural Hall of Fame. He resides on the family farm in Prince Edward County.",
		img: "lyle.jpg",
	},
	{
		name: "Eric Wetlaufer",
		content:
			"Has over 30 years of experience in investment management. Eric was previously responsible for leading the $120 billion Public Market Investments Group at CPPIB. Prior managerial roles include: Group Chief Investment Officer, International at Fidelity Management & Research in Boston. Chief Investment Officer at Putnam Investments and Managing Director at Cadence Capital Management. Eric serves on the Board of Directors of TMX Group and Aion.",
		img: "eric.png",
	},
	{
		name: "David Ewasuik",
		content:
			"With over 30 years of experience in the IT industry. Currently the Chairman CEO of Market Evolution group of companies, who develop and support traditional and blockchain Exchanges and Clearing organisations. Been involved in software set up of 80+ Exchanges and National CDS's. Currently a director of the Bahamas International Stock Exchange, various blockchain and technology committees and boards.",
		img: "david.png",
	},
	{
		name: "Nicole Rogers",
		content:
			"Nicole was raised alongside her family’s agri-trading and marketing businesses including, Farms.com, North America’s first agriculture sector focused web platform. Before founding Agriprocity – an agricultural advisory and investment firm with a focus on building disruptive supply chains, she was the Trade Attaché for Agriculture and Agri-food Canada (UAE). Nicole is a delegate on the United Nations Committee for Food Security, a founding member of the Dubai Food Trade Advisory Group and is an active angel investor and advisor in multiple Agtech start-ups. ",
		img: "nicole.png",
	},
	{
		name: "Victor Li",
		content:
			"Victor has over 20 years of investment research experience with leading Canadian pension funds, covering real estate, economics, and fixed income. His paper on real estate was published in Canadian Investment Review. Victor has been studying blockchain since 2014 and co-founded the CPPIB blockchain community in 2015. His research interests include cryptoeconomics and decentralized finance. He is on George Brown College's blockchain program advisory committee and was on Toronto Blockchain Week 2019’s steering committee. Victor is a CFA charterholder.",
		img: "victor.png",
	},
	{
		name: "Cy Monley",
		content:
			"Cy has over 25 years of ag commodity experience. He is the currently the CEO of Silveus Financial, and on the executive committee of the Farmers Business Network.",
		img: "cy.png",
	},
	{
		name: "David Pupo",
		content:
			"David has over 25 years of corporate and investment banking experience including, most recently, a dual role as Chief Risk Officer at a large agriculture company and VP Corporate Development and Strategic Partnerships at Genesis Grain & Fertilizer. Previously, David was the Global Head of Fertilizer Risk Management and Senior Equity Analyst at Macquarie Bank Limited covering the grain handling, agriculture equipment and fertilizer sectors.",
		img: "davidP.png",
	},
];

export const supporters = [
	{
		name: "AgLaunch",
		img: "AgLaunch.png",
		link: "http://aglaunch.com/",
	},
	{
		name: "Creative Destruction Lab",
		img: "cdl.png",
		link: "https://www.creativedestructionlab.com/",
	},
	{
		name: "Ontario Agri-Food Technologies",
		img: "OAFT.png",
		link: "http://oaft.org/",
	},
	{
		name: "Canadian Seed Growers Association",
		img: "CSGA.png",
		link: "https://seedgrowers.ca/",
	},
	{
		name: "Community Futures: Prince Edward, Lennox, & Addington Counties",
		img: "community-futures.png",
		link: "http://www.pelacfdc.ca/",
	},
	{
		name: "ReWatt power",
		img: "ReWatt.png",
		link: "https://www.rewattpower.com/",
	},
	{
		name: "MaRs",
		img: "mars.jpg",
		link: "https://www.marsdd.com/",
	},
	{
		name: "Jonah Group",
		img: "jonahGroup.svg",
		link: "https://www.jonahgroup.com/",
	},
	{
		name: "Northumberland Community Futures Development Corporation",
		img: "Northumberland.png",
		link: "https://financingandstrategy.com/",
	},
	{
		name: "Onaterio Centres of Excellence",
		img: "once.jpg",
		link: "https://www.oce-ontario.org/",
	},
	{
		name: "Grain Farmers of Ontario",
		img: "gfo.jpg",
		link: "https://gfo.ca/",
	},

	{
		name: "Olds College",
		img: "olds-college.jpg",
		link: "https://www.oldscollege.ca/shopoc/brewery/index.html",
	},
	{
		name: "Decisive Farming",
		img: "decisive-farming.png",
		link: "https://decisivefarming.com/",
	},
	{
		name: "Government of Canada",
		img: "government.png",
	},
];
