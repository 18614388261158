import React from "react";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
	return (
		<>
			<div className="PrivacyPolicy">
				<h1>Privacy Policy</h1>
				<br />
				<h3>Data</h3>
				<p>
					All client account information and data generated (i.e., bids, target
					offers, confirmed contracts) will not be shared to any third-party
					organization. Account information collected is for the use of Grain
					Discovery to verify individual's location for FOB farm contracts &
					connection with the grain elevator inventory system.
				</p>
				<br />
				<h3>General</h3>
				<p>
					Passwords - users are responsible for the creation, editing and
					privacy of their passwords. Grain Discovery cannot access passwords at
					any time for any reason. Communication to the elevator's clients
					through the Grain Discovery platform will be for internal use only
					Links to other websites - any activity engaged through other
					technology providers through our platform would be subject to their
					data privacy requirements if and when they are accessed. The grain
					buyer will be responsible for providing access to their system to
					producers and users on their own accord. Grain Discovery will support
					this access at the request of the elevator to either sign up customers
					on their behalf OR enable individual users
				</p>
				<h3>Links to other websites</h3>
				<p>
					Our Service may contain links to third-party web sites or services
					that are not owned or controlled by Grain Discovery. Grain Discovery
					has no control over, and assumes no responsibility for, the content,
					privacy policies, or practices of any third party web sites or
					services. You further acknowledge and agree that Grain Discovery shall
					not be responsible or liable, directly or indirectly, for any damage
					or loss caused or alleged to be caused by or in connection with use of
					or reliance on any such content, goods or services available on or
					through any such web sites or services. We strongly advise you to read
					the terms and conditions and privacy policies of any third-party web
					sites or services that you visit.
				</p>
			</div>
		</>
	);
}

export default PrivacyPolicy;
