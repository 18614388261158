import React, {useState} from 'react';
import './TeamFormat.css';

const FormatSupporters = ({ item, width }) => {
    const [hover, setHover] = useState(false);
    const imgWidth = width >= 30 ? '175px' : '100px';

    const supporter = (
        <img
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className={!hover && 'bw'}
            width={imgWidth}
            src={require('../../assets/images/supporters/' + item.img)}
            alt={item.name}>
        </img>
    )
    return (
        <div className="Supporters">
            {item.link ?
                <div>
                    <a href={item.link} target="_blank" rel="noopener noreferrer" >{supporter}</a>
                </div> :
                <div>
                    {supporter}
                </div>}
        </div>
    );
}

export default FormatSupporters;